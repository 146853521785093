import React from 'react';
import cx from 'classnames';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './styles.sass';

class DefaultLayout extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;

    /* replace links in confidentialité and consentement page */
    if (post.frontmatter.noFollowLinks)
      post.html = post.html.replace(/<a href=/gi, '<a target="_blank" rel="noopener noreferrer nofollow" href=');

    return (
      <Container className="mt-5">
        <Row>
          <Col xs={12} md={{ offset: 2, span: 8 }}>
            <div
              className={cx(
                'blog-post-content pt-5',
                post.frontmatter.wrapperClass,
              )}
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default DefaultLayout;
