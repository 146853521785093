import React from 'react';
import cx from 'classnames';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class IfopOperation extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const navigationComponent = this.props.navigation;

    return (
      <>
        <Container>
          <Row>
            <Col md={{ order: 2 }}>
              {navigationComponent}
            </Col>
            <Col xs={12} md={{ order: 1, span: 8 }}>
              <div
                className={cx(
                  'blog-post-content pt-5',
                  post.frontmatter.wrapperClass,
                )}
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default IfopOperation;
