import React from 'react';
import Infographie from 'images/open-iconic/svg/infographie.svg';
import CompteRendu from 'images/open-iconic/svg/compte-rendu.svg';
import EnqueteComplete from 'images/open-iconic/svg/enquete-complete.svg';
import IfopNavigation from '../IfopNavigation';

class IfopConfinementNavigation extends React.Component {
  render() {
    return (
      <>
        <IfopNavigation
          title="Étude IFOP - Charles"
          links={[
            {
              to: '/blog/etudes-et-sondages/sondage-ifop-vie-sexuelle-des-francais-confines/',
              className: '',
              content: (
                <>
                  <CompteRendu height="18" width="18" />
                  <span className="ml-1">Voir le compte-rendu</span>
                </>
              ),
            },
            {
              to: '/blog/etudes-et-sondages/sondage-ifop-vie-sexuelle-des-francais-confines/',
              className: '',
              content: (
                <>
                  <EnqueteComplete height="18" width="18" />
                  <span className="ml-1">Voir l&apos;enquête complète</span>
                </>
              ),
            },
            // {
            //   to: "/blog/etudes-et-sondages/sondage-ifop-coronavirus-habitudes-des-francais/",
            //   className: "lc-light-blue",
            //   content: (
            //     <>
            //       <PaperclipIcon height="12" width="12" />
            //       <span className="ml-1">Infographie</span>
            //     </>
            //   )
            // }
          ]}
        />
      </>
    );
  }
}

export default IfopConfinementNavigation;
