import IfopNavigation from 'components/IfopNavigation';
import IfopDENavigation from 'components/IfopDENavigation';
import IfopEPNavigation from 'components/IfopEPNavigation';
import IfopCoronaNavigation from 'components/IfopCoronaNavigation';
import IfopConfinementNavigation from 'components/IfopConfinementNavigation';
import IfopPilositeNavigation from 'components/IfopPilositeNavigation';
import IfopOperation from './IfopOperation';
import DefaultLayout from './Default';

const LAYOUT_TO_LAYOUT_COMP = {
  IfopOperation,
  IfopNavigation,
  IfopDENavigation,
  IfopEPNavigation,
  IfopCoronaNavigation,
  IfopConfinementNavigation,
  IfopPilositeNavigation,
};

const chooseLayout = (post) => LAYOUT_TO_LAYOUT_COMP[post.frontmatter.layout] || DefaultLayout;
// eslint-disable-next-line max-len
const chooseNavigation = (post) => LAYOUT_TO_LAYOUT_COMP[post.frontmatter.navigation] || IfopNavigation;
export { chooseLayout, chooseNavigation };
