import React from 'react';
import PaperclipIcon from 'images/open-iconic/svg/paperclip.svg';
import GraphIcon from 'images/open-iconic/svg/graph.svg';
import BookIcon from 'images/open-iconic/svg/book.svg';
import IfopNavigation from '../IfopNavigation';

class IfopPilositeNavigation extends React.Component {
  render() {
    return (
      <>
        <IfopNavigation
          title="Étude IFOP - Charles"
          links={[
            {
              to: '/blog/etudes-et-sondages/sondage-ifop-pratiques-depilatoires/',
              className: '',
              content: (
                <>
                  <GraphIcon height="12" width="12" />
                  <span className="ml-1">
                    Voir le compte-rendu
                  </span>
                </>
              ),
            },
            {
              to: '/blog/etudes-et-sondages/sondage-ifop-pratiques-depilatoires/',
              className: '',
              content: (
                <>
                  <BookIcon height="12" width="12" />
                  <span className="ml-1">
                    Voir l&apos;enquête complète
                  </span>
                </>
              ),
            },
            {
              to: '/blog/etudes-et-sondages/sondage-ifop-pratiques-depilatoires/',
              className: '',
              content: (
                <>
                  <PaperclipIcon height="12" width="12" />
                  <span className="ml-1">Infographie</span>
                </>
              ),
            },
          ]}
        />
      </>
    );
  }
}

export default IfopPilositeNavigation;
