import React from 'react';
import CharlesMeta from 'components/common/meta/CharlesMeta';
import StructuredWebPage from 'components/common/meta/StructuredWebPage';
import { graphql } from 'gatsby';
import CharlesLayout from 'components/common/CharlesLayout';
import { chooseLayout, chooseNavigation } from './OperationLayout';
import MetaJson from './mdMetaData.json'

class MarkdownLayout extends React.Component {

  render() {
    const post = this.props.data.markdownRemark;
    const LayoutComponent = chooseLayout(post);
    const NavigationComponent = chooseNavigation(post);

    const metaData = MetaJson[this.props.path]

    return (
      <CharlesLayout showLinksInFooter={false}>
        <CharlesMeta
          title={post.frontmatter.title}
          description={metaData ? metaData.description : post.frontmatter.description}
          image={post.frontmatter.shareImage && post.frontmatter.shareImage.publicURL}
        />
        <StructuredWebPage
          title={post.frontmatter.title}
          description={metaData ? metaData.description : post.frontmatter.description}
        />
        <LayoutComponent data={this.props.data} navigation={<NavigationComponent />} />
      </CharlesLayout>
    );
  }
}

export default MarkdownLayout;

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        tags
        date(formatString: "MMMM DD, YYYY")
        author
        noFollowLinks
      }
    }
  }
`;
